.button {
  display: block;

  height: 46px;

  text-align: center;

  border-radius: 8px;

  cursor: pointer;

  line-height: 26px;
  font-size: 1rem;
  font-weight: 500;
}
