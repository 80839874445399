.article-item {
  display: flex;

  padding: 25px 0 20px;

  border-bottom: 1px solid #ececec;
}

.article-popularity-wrapper {
  display: flex;
  flex-direction: column;

  width: 35px;

  color: #555;
  text-align: center;

  .article-popularity {
    font-size: 13px;
    font-size: 0.8125rem;
    font-weight: 600;
  }
}

.article-thumbnail {
  grid-area: article-thumbnail;

  max-width: 100%;
  max-height: 100%;
}

.article {
  display: grid;
  grid-template-areas:
    "article-header article-thumbnail"
    "article-meta article-thumbnail"
    "article-teaser-preview article-thumbnail"
    "article-footer article-thumbnail";
  grid-template-columns: auto 200px;
  grid-template-rows: auto auto auto 1fr;
  grid-gap: 0px;

  min-height: 100px;
  width: 100%;

  padding-left: 4px;

  > :not(:last-child) {
    margin-bottom: 7px;

    &.article-thumbnail {
      margin-bottom: 0;
    }
  }
}

.article-header {
  display: flex;
  flex-direction: column-reverse;
}

.article-title {
  font-size: 21px;
  font-size: 1.3125rem;
}

.article-topics-row {
  display: flex;

  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 400;

  color: #555;

  :not(:last-child) {
    &:after {
      // this adds a dot . between elements
      content: "·";
      margin: 0 2px;
    }
  }

  .article-topic {
    display: inline-block;
    // unicode-bidi: isolate; // no idea what this is
  }
}

.article-meta {
  grid-area: article-meta;
  display: flex;

  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;

  :not(:last-child) {
    margin-right: 4px;
  }
}

.article-teaser-preview {
  grid-area: article-teaser-preview;

  color: #404040;

  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 600;
}

.article-source {
  a {
    color: #555;
  }
}

.article-author {
  a {
    color: #555;
  }
}

.article-footer {
  grid-area: article-footer;

  display: flex;

  font-size: 13px;
  font-size: 0.8125rem;

  :not(:last-child) {
    &:after {
      // this adds a dot . between elements
      content: "·";
      margin: 0 2px;
    }
  }
}

/* // commented out reddit-flair styling
  padding: 0 2px;
  margin-right: 0.5em;

  background-color: #f5f5f5;
  border-color: #000000;

  border: 1px solid #ddd;
  border-radius: 3px; */
