.register-page {
  grid-area: main;

  display: grid;
  grid-template-areas: "left right";
  grid-template-columns: 40% 60%;

  background: url(../../bg4.jpeg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.register-form {
  grid-area: left;

  background-color: white;

  margin: auto;
  padding: 100px 50px;

  border-radius: 45px;

  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.6);

  :not(:last-child) {
    margin: 0 auto 2rem;
  }

  .register-form-header {
    text-align: center;

    margin-bottom: 4rem;
  }

  .button {
    padding: 0 3rem;

    margin: 0 auto;

    color: white;
    background-color: #ebb400;
  }
}
