* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-container {
  display: grid;
  height: 100%;
  max-width: 100vw;

  margin: 0 auto;

  grid-template-areas:
    "header"
    "main"
    "footer";

  grid-template-rows: auto 1fr auto;
}

a {
  text-decoration: none;

  color: unset;

  &:hover {
    text-decoration: underline;
  }
}

li {
  list-style: none;
}
