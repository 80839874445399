.input-wrapper {
  display: flex;
  flex-direction: column;

  width: 400px;
  padding: 0 24px;

  .input-label {
    margin-bottom: 3px;
  }

  .input {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    color: #495057;
    background-color: white;

    padding: 0.65rem 1rem;

    border: 1px solid #ebedf2;
    border-radius: 4px;
  }
}
