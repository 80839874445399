.header {
  grid-area: header;

  display: flex;
  justify-content: space-between;

  width: 1160px;

  padding: 28px 16px;
  margin: 0 auto;

  border-bottom: 1px solid #ececec;

  line-height: 38px;
  font-size: 24px;
  font-weight: 700;
}

.navlink {
  :not(:last-child) {
    margin-right: 6px;
  }
}
